<template>
  <v-sheet
    dense
    v-bind="$attrs"
    class="d-flex align-item-center fill-height clock"
    height="120"
  >
    <div class="align-self-center mx-auto primary--text">
      <v-row
        no-gutters
        class="text-center flex-nowrap"
        dense
        justify="center"
        align="center"
      >
        <v-col>
          <h2 class="subtitle-2 mb-3">HRS</h2>
          <h2 class="mx-1 font-weight-bold fs-40">{{ running.hrs }}</h2>
        </v-col>
        <h1 class="mt-7">:</h1>
        <v-col class="mid-clock">
          <h2 class="subtitle-2 mb-3">MIN</h2>
          <h2 class="mx-1 font-weight-bold fs-40">{{ running.min }}</h2>
        </v-col>
        <h1 class="mt-7">:</h1>
        <v-col>
          <h2 class="subtitle-2 mb-3">SEC</h2>
          <h2 class="mx-1 font-weight-bold fs-40">{{ running.sec }}</h2>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    timeString: { type: String, default: '00:00:00', required: true }
  },
  data: () => ({
    clock: '00:00:00',
    interval: undefined,
    clock_is_running: false,
    hrs: 0,
    min: 0,
    sec: 0
  }),
  computed: {
    running() {
      let split = this.clock.split(':')
      return {
        hrs: split[0],
        min: split[1],
        sec: split[2]
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.runClock()
        else this.pauseClock()

        this.clock_is_running = val
      },
      immediate: true
    },
    clock_is_running(val) {
      this.$emit('input', val)
    },
    timeString: {
      handler: function (val) {
        this.clock = val
      },
      immediate: true
    }
  },
  methods: {
    get_hours(timeString) {
      return timeString.split(':')[0]
    },
    get_mins(timeString) {
      return timeString.split(':')[1]
    },
    get_secs(timeString) {
      return timeString.split(':')[2]
    },
    pauseClock() {
      clearInterval(this.interval)
      this.clock_is_running = false
    },
    runClock() {
      this.clock_is_running = true
      let _this = this
      this.interval = setInterval(this.doClocking, 1000)
    },
    doClocking() {
      let hours = Number(this.get_hours(this.clock))
      let mins = Number(this.get_mins(this.clock))
      let secs = Number(this.get_secs(this.clock))

      if (secs >= 59 && mins >= 59) {
        hours++
        mins = secs = 0
      } else if (secs >= 59) {
        mins++
        secs = 0
      } else {
        secs++
      }
      this.clock = this.set_time_running(hours, mins, secs)
    },
    set_time_running(hours, mins, secs) {
      if (hours.toString().length === 1) hours = '0' + hours
      if (mins.toString().length === 1) mins = '0' + mins
      if (secs.toString().length === 1) secs = '0' + secs
      return `${hours}:${mins}:${secs}`
    }
  }
}
</script>

<style lang="scss" scoped>
.clock {
  .heading-1 {
    font-size: 50px;
  }
}
</style>
